<template>
  <div class="dots">
    <div class="dots-switcher">
      <a href="javascript:void(0);" @click="switchDotsScreen('dots')" :class="screen === 'dots' ? 'active' : ''">Экстерьер</a>
      <a href="javascript:void(0);" @click="switchDotsScreen('dots_internal')" :class="screen === 'dots_internal' ? 'active' : ''">Интерьер</a>
    </div>
    <div class="presentation-dots">
      <div class="dots-wrapper">
        <picture class="w-100" v-if="presentation !== null">
          <source media="(min-width: 1441px)" :srcset="srcset(1920,3840)" v-if="isSizeExists(1920, 3840)">
          <img class="w-100" :src="sized[Math.max(...Object.keys(sized))]">
        </picture>
        <dot v-for="dot in dots" :dot="dot" v-if="presentation !== null" />
      </div>
    </div>
  </div>

</template>

<script>
  import dot from "@/components/dots/dot.vue"
  export default {
    components: {dot},
    data: ()=> {
      return {
        presentation: null,
        screen: 'dots'
      }
    },
    mounted() {
      this.presentation = window.gon.presentation
    },
    methods: {
      switchDotsScreen(screen) {
        this.screen = screen
      },
      srcset(size1,size2) {
        if (this.sized[size1] !== undefined && this.sized[size2] !== undefined) {
          return `${this.sized[size1]} 1x, ${this.sized[size2]} 2x`
        } else if (this.sized[size1] !== undefined) {
          return `${this.sized[size1]} 1x, ${this.sized[size1]} 2x`
        } else {
          return `${this.sized[size2]} 1x, ${this.sized[size2]} 2x`
        }
      },
      isSizeExists(size1, size2) {
        return this.sized[size1] !== undefined || this.sized[size2] !== undefined
      }
    },
    computed: {
      images() {
        return this.presentation.head_photos.filter( php => php.screen === this.screen).sort((a, b) => b.size - a.size)
      },
      dots() {
        return this.presentation.dots.filter(dot => dot.screen === this.screen)
      },
      sized() {
        return this.images.reduce(
            (obj, item) => Object.assign(obj, { [item.size]: item.img }), {});
      }
    }
  }
</script>

<style scoped>
.presentation-dots {
  user-select: none;
}

.dots-wrapper {
  position: relative;
}

.dots-switcher {
  display: flex;
  margin-bottom: 50px;
  padding: 0 60px;
  gap: 40px;
}

.dots-switcher a {
  font-size: 16px;
  text-underline-offset: 3px;
  color: #808080;
}

.dots-switcher a.active {
  color: #000000;
}

@media (max-width: 800px) {
  .dots {
    display: none;
  }
}

</style>