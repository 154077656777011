<template>
  <div class="p-dot" :style="currentStyle">
    <modal :dot="dot" v-if="show_modal" @close="toggleModal()" />
    <svg @click="toggleModal()" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_4784_6983)">
        <rect width="60" height="60" rx="30" fill="white" fill-opacity="0.2" style="cursor: pointer;"/>
        <circle cx="30.5" cy="30.5" r="7" fill="white" stroke="black"/>
      </g>
      <defs>
        <filter id="filter0_b_4784_6983" x="-30" y="-30" width="120" height="120" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="15"/>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4784_6983"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4784_6983" result="shape"/>
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
import modal from './modal.vue'
export default {
  props: ['dot'],
  components: {modal},
  data: ()=> {
    return {
      show_modal: false,
      ro: null,
      parent_node: null,
      top: 0,
      left: 0,
      refreshKey: 0,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  mounted() {
    this.parent_node = document.querySelector('.presentation-dots')
    this.ro = new ResizeObserver(this.updateStyle)
    this.ro.observe(this.parent_node)
  },
  methods: {
    toggleModal() {
      this.show_modal = !this.show_modal
    },
    leftToPercents() {
      return Math.round(((this.$refs.draggableContainer.offsetLeft *100)/this.parent_node.offsetWidth)*100)/100;
    },
    topToPercents() {
      return Math.round(((this.$refs.draggableContainer.offsetTop*100)/this.parent_node.offsetHeight)*100)/100;
    },
    percentsToLeft() {
      return Math.round((this.dot.left * this.parent_node.offsetWidth)/100);
    },
    percentsToTop() {
      return Math.round((this.dot.top * this.parent_node.offsetHeight)/100);
    },
    updatePosition() {
      api.put(`/admin/presentation_dots/${this.dot.id}`, JSON.stringify({left:this.leftToPercents(),top:this.topToPercents()}))
    },
    updateStyle() {
      this.refreshKey += 1;
    }
  },
  computed: {
    currentStyle() {
      if (this.parent_node !== null) {
        this.refreshKey;
        return `top: ${this.percentsToTop()}px; left: ${this.percentsToLeft()}px;`
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.p-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
  transition: ease-in 0.3s;
}

.p-dot:hover rect {
  fill: black
}

</style>
