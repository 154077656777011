<template>
  <div id="pressa">
    <div class="pressa-switcher">
      <img :src="quote.logo" alt="" v-for="(quote,index) in quotes" :class="current_index === index ? '' : 'grayed'" @click="changeSlide(index)">
    </div>
    <Carousel ref="carousel" v-bind="config" @slide-end="updateIndex">
      <Slide v-for="quote in quotes">
        <div class="pressa-quote">
          <h3 v-html="quote.title"></h3>
          <a class="connect" :href="quote.url" target="_blank">
            <span>Подробнее</span>
            <img alt="" class="shevron-connect" :src="'/img/shevron-right.svg'" />
          </a>
        </div>

      </Slide>
    </Carousel>
  </div>
</template>

<script>
  import 'vue3-carousel/dist/carousel.css'
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
  export default {
    components: {Carousel, Slide, Pagination, Navigation},
    data: ()=> {
      return {
        config:{},
        quotes: [
          {title:"«Партнёрство Сбера и&nbsp;международного импортера Terminal&nbsp;A сделает процесс доставки авто из-за рубежа проще и&nbsp;безопаснее»",
          url:"https://www.sberbank.ru/ru/sberpress/all/article?newsID=0753ec20-48b8-4bc6-b1de-94f61ca94187&blockID=1303&regionID=77&lang=ru&type=NEWS",
          logo:"/img/sber_color.png"},
          {title:"«В&nbsp;России состоялась первая покупка автомобиля из&nbsp;Китая с&nbsp;использованием аккредитива. Операторами сделки выступили Сбер и Terminal&nbsp;A».",
          url:"https://www.autostat.ru/finance/59244/",
          logo:"/img/avtostat.png"}
        ],
        current_index: 0
      }
    },
    methods: {
      updateIndex(event) {
        this.current_index = event.currentSlideIndex
      },
      changeSlide(index) {
        this.current_index = index
        this.$refs.carousel.slideTo(index)
      }
    }

  }
</script>

<style>

</style>