<template>
  <div class="dot-modal" @click="closeModal($event)">
    <div class="dot-modal-window">
      <div class="dot-modal-txt">
        <h4>{{dot.title}}</h4>
        <p>{{dot.description}}</p>
      </div>

      <div class="dot-modal-img">
        <img class="dot-img" :src="dot.img">
      </div>
      <img class="dot-modal-closer" :src="'/img/close-round-white.svg'" @click="close()" />
    </div>
  </div>
</template>

<script>

export default {
  props: ['dot'],
  data: ()=> {
    return {

    }
  },
  mounted() {
    document.addEventListener("keydown", ({key}) => {
      if (key === "Escape") {
        this.close()
      }
    })
  },
  methods: {
    close() {
      this.$emit('close',{})
    },
    closeModal(event) {
      //console.log(event.target)
      if (event.target.classList.contains('dot-modal')) {
        this.close()
      }
    }
  }
}
</script>

<style scoped>
.dot-modal {
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(1, 2, 5, 0.67);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(32px);
}

.dot-modal-window {
  background: #ffffff;
  display: flex;
  gap: 100px;
  position: relative;
  border-radius: 8px;
}

.dot-modal-txt {
  padding-left: 40px;
  margin-top: 120px;
  width: 650px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow-y: auto;
  max-height: 300px;
  scrollbar-color: rgba(0, 0, 0, 0.5) #ffffff !important;
  scrollbar-width: thin !important;
  position: relative;
}

.dot-img {
  border-radius: 0 8px 8px 0;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1440px) {
  .dot-modal-txt {
    width: 474px;
  }

}

@media (max-width: 1200px) {
  .dot-modal-txt {
    width: 474px;
  }
}


.dot-modal-txt a {
  text-decoration: none;
}

.dot-modal-txt h4 {
  font-size: 62px;
  font-weight: normal;
}

.dot-modal-txt p {
  font-size: 24px;
}

.dot-modal-img {
  width: 750px;
}

@media (max-width: 1440px) {
  .dot-modal-txt {
    width: 565px;
  }
  .dot-modal-txt h4 {
    font-size: 50px;
  }
  .dot-modal-txt p {
    font-size: 22px;
  }
  .dot-modal-img {
    width: 565px;
  }
  .dot-modal-txt {
    margin-top: 80px;
  }

}

@media (max-width: 1200px) {
  .dot-modal-txt {
    width: 325px;
  }
  .dot-modal-txt h4 {
    font-size: 30px;
  }
  .dot-modal-txt p {
    font-size: 16px;
  }
  .dot-modal-img {
    width: 465px;
  }
  .dot-modal-txt {
    margin-top: 60px;
  }
}

.dot-img {
  width: 100%;
}

.dot-modal-closer {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  transition: transform .7s ease-in-out;
}

.dot-modal-closer:hover {
  transform: rotate(180deg);
}


@media (max-width: 800px) {
  .presentation-dots {
    display: none;
  }
}

</style>