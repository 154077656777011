<template>
  <div class="p-stuff">
    <div class="tab-switcher" v-if="presentation !== null">
      <a v-for="(tab,index) in presentation.stuff_tabs" :class="selected_tab === index ? 'active' : ''" href="javascript:void(0)" @click="switchTab(index)">{{tab.name}}</a>
    </div>
    <div class="slides">
      <div class="stuff-slide" v-for="slide in currentSlides">
        <img :src="slide.image" class="w-100 round-10">
        <h5>{{slide.title}}</h5>
        <p>{{slide.description}}</p>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data: ()=> {
      return {
        presentation: null,
        selected_tab: 0
      }
    },
    mounted() {
      this.presentation = window.gon.presentation
      this.setGrabScroll()
    },
    methods: {
      switchTab(index) {
        this.selected_tab = index
      },
      setGrabScroll() {
        const elem = document.querySelector('.slides')
        const dragStart = (ev) => elem.setPointerCapture(ev.pointerId);
        const dragEnd = (ev) => elem.releasePointerCapture(ev.pointerId);
        const drag = (ev) => elem.hasPointerCapture(ev.pointerId) && (elem.scrollLeft -= ev.movementX);

        elem.addEventListener("pointerdown", dragStart);
        elem.addEventListener("pointerup", dragEnd);
        elem.addEventListener("pointermove", drag);
      }
    },
    computed: {
      currentSlides() {
        if (this.presentation === null) {
          return []
        } else {
          return this.presentation.stuff_tabs[this.selected_tab].slides
        }
      }
    }
  }
</script>

<style scoped>
  .p-stuff {
    padding: 200px 0;
  }

  .tab-switcher {
    padding-left: 60px;
    margin-bottom: 50px;
    display: flex;
    gap: 40px;
  }

  .tab-switcher a {
    font-size: 16px;
    color: #808080;
  }

  .tab-switcher a.active {
    color: #000;
  }

  .slides {
    padding: 0 60px;
    display: flex;
    gap: 30px;
    max-width: 100%;
    overflow-x: hidden;
    scrollbar-color: rgba(0, 0, 0, 0.5) #ffffff !important;
    scrollbar-width: thin !important;
    user-select: none;
    touch-action: none;
    cursor: grab;
  }

  .stuff-slide {
    display: flex;
    flex-direction: column;
    width: 480px;
    flex-shrink: 0;
  }

  .stuff-slide img {
    margin-bottom: 20px;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
  }

  .stuff-slide h5 {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .stuff-slide p {
    font-size: 16px;
    color: #808080;
  }

  @media (max-width: 1440px) {
    .p-stuff {
      padding: 150px 0;
    }
    .tab-switcher {
      padding-left: 40px;
    }
    .slides {
      padding: 0 40px;
    }
    .stuff-slide {
      width: 380px;
    }
    .stuff-slide h5 {
      font-size: 24px;
    }
  }

  @media (max-width: 1200px) {
    .tab-switcher {
      margin-bottom: 40px;
    }
    .stuff-slide {
      width: 330px;
    }
    .stuff-slide h5 {
      font-size: 22px;
    }
  }

  @media (max-width: 450px) {
    .p-stuff {
      padding: 120px 0;
    }
    .tab-switcher {
      margin-bottom: 30px;
    }
    .slides {
      padding: 0 20px;
    }
  }

  @media (max-width: 350px) {
    .p-stuff {
      padding: 0 0 100px 0;
    }
  }

</style>