<template>
  <div class="p-carousel">
    <Carousel ref="carousel" v-bind="config" @slide-end="updateIndex">
      <Slide v-for="slide in slides" :key="slide">
        <picture class="w-100">
          <source media="(min-width: 1441px)" :srcset="`${slide.desktop_fullhd} 1x, ${slide.desktop_4k} 2x`">
          <source media="(min-width: 769px)" :srcset="`${slide.desktop_hd} 1x, ${slide.desktop_2k} 2x`">
          <source media="(min-width: 431px)" :srcset="`${slide.tablet} 1x, ${slide.tablet_retina} 2x`">
          <source media="(min-width: 1px)" :srcset="`${slide.mobile} 1x, ${slide.mobile_retina} 2x`">
          <img class="w-100 br-10" :src="`${slide.desktop_fullhd}`">
        </picture>
      </Slide>
      <template #addons>
        <Navigation>
          <template #next>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="60" viewBox="0 0 30 60" fill="none">
              <path d="M1.87023 0C1.39579 0 0.910259 0.201402 0.546154 0.592359C-0.182055 1.37427 -0.182055 2.65377 0.546154 3.43568L25.4708 30.1984L0.910257 56.5702C0.182049 57.3522 0.182049 58.6316 0.910257 59.4136C1.63847 60.1955 2.83008 60.1955 3.55829 59.4136L29.4538 31.6201C30.1821 30.8382 30.1821 29.5587 29.4538 28.7768L3.20522 0.592359C2.83008 0.189555 2.3557 0 1.87023 0Z" fill="white"/>
            </svg>
          </template>
          <template #prev>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="60" viewBox="0 0 30 60" fill="none">
              <path d="M28.1298 0C28.6042 0 29.0897 0.201402 29.4538 0.592359C30.1821 1.37427 30.1821 2.65377 29.4538 3.43568L4.52924 30.1984L29.0897 56.5702C29.818 57.3522 29.818 58.6316 29.0897 59.4136C28.3615 60.1955 27.1699 60.1955 26.4417 59.4136L0.546157 31.6201C-0.182052 30.8382 -0.182052 29.5587 0.546157 28.7768L26.7948 0.592359C27.1699 0.189555 27.6443 0 28.1298 0Z" fill="white"/>
            </svg>
          </template>
        </Navigation>
      </template>
    </Carousel>
    <div class="g-thumbs">
      <img  v-for="(slide,index) in slides" :key="slide" :src="slide.thumb" class="g-thumb round-10" :class="current_index === index ? '' : 'blurred'" :data-index="index">
    </div>
    <div class="g-paginator">
      <div class="g-pager"  v-for="(slide,index) in slides" :key="slide" :class="current_index === index ? 'selected' : ''" @click="changeSlide(index)" :data-index="index"></div>
    </div>
  </div>

</template>

<script>
  import 'vue3-carousel/dist/carousel.css'
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
  export default {
    props: ['slides'],
    components: {Carousel, Slide, Pagination, Navigation},
    data: ()=> {
      return {
        config:{},
        current_index:0
      }
    },
    mounted() {
      this.setGrabScroll()
    },
    methods: {
      updateIndex(event) {
        this.current_index = event.currentSlideIndex
      },
      changeSlide(index) {
        this.current_index = index
        this.$refs.carousel.slideTo(index)
      },
      setGrabScroll() {
        const _this = this
        const elem = document.querySelector('.g-thumbs')
        const dragStart = (ev) => elem.setPointerCapture(ev.pointerId);
        const dragEnd = (ev) => elem.releasePointerCapture(ev.pointerId);
        const drag = (ev) => {
          elem.hasPointerCapture(ev.pointerId) && (elem.scrollLeft -= ev.movementX)
        }

        elem.addEventListener("pointerdown", dragStart);
        elem.addEventListener("pointerup", dragEnd);
        elem.addEventListener("pointermove", drag);

        // хак для работы changeSlide()
        elem.addEventListener("mousedown", (ev)=>{
          if (ev.target.tagName === 'IMG') {
            window.scroll_pos = ev.clientX
            window.image_index = parseInt(ev.target.dataset.index)
          } else {
            window.scroll_pos = null
          }
        });

        elem.addEventListener("mouseup", (ev)=>{
          if (window.scroll_pos !== null && Math.abs(window.scroll_pos - ev.clientX) === 0) {
            _this.changeSlide(window.image_index)
          }
        });

      }
    }
  }
</script>

<style>
  .carousel__prev {
    left:  30px;
  }

  .carousel__next {
    right:  30px;
}

.carousel__viewport {
  border-radius: 10px;
}

@media (max-width: 450px) {
  .carousel__prev {
    left:  0;
  }

  .carousel__prev,  .carousel__next {
    width: 21px;
    height: 11px;
  }

  .carousel__next {
    right:  0;
  }

}

</style>

<style scoped>

  .g-paginator {
    display: none;
  }

  .g-thumbs {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: safe center;
    gap: 20px;
    overflow-x: hidden;
    scrollbar-color: rgba(0, 0, 0, 0.5) #ffffff !important;
    scrollbar-width: thin !important;
    user-select: none;
    touch-action: none;
    cursor: grab;
  }

  .g-thumb {
    margin-bottom: 20px;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    width: 240px;
    cursor: pointer;
    position: relative;
    flex-shrink: 0;
  }

  .blurred {
    opacity: 0.4;
  }


  @media (max-width: 1440px) {
    .g-thumb {
      width: 171px;
    }
  }

  @media (max-width: 1200px) {
    .g-thumb {
      width: 142px;
    }
  }

  @media (max-width: 760px) {
    .g-thumb {
      width: 130px;
    }
  }

  @media (max-width: 450px) {

    .g-thumbs {display: none}

    .g-paginator {
      display: flex;
      justify-content: center;
      gap: 20px;
      padding: 20px 0;
    }

    .g-pager {
      width: 10px;
      height: 10px;
      background-color: #DBDBDB;
      border-radius: 50%;
    }

    .g-pager.selected {
      background-color: #808080;
    }
  }


</style>