<template>
  <div class="p-gallery">
    <div class="g-switcher">
      <a @click="switchScreen('external')" href="javascript:void(0)" :class="screen === 'external' ? 'active' : ''">Экстерьер</a>
      <a @click="switchScreen('internal')" href="javascript:void(0)" :class="screen === 'internal' ? 'active' : ''">Интерьер</a>
    </div>
    <gallery :slides="currentSlides"/>
  </div>
</template>

<script>
  import gallery from '@/components/presentation_gallery/gallery.vue'
  export default {
    components: {gallery},
    data: ()=> {
      return {
        presentation: null,
        screen: 'external'
      }
    },
    created() {
      this.presentation = window.gon.presentation
    },
    methods: {
      switchScreen(screen) {
        this.screen = screen
      }
    },
    computed: {
      currentSlides() {
        if (this.presentation === null) {
          return []
        } else {
          return this.presentation.photos.filter((photo) => photo.kind === this.screen)
        }
      }
    }
  }
</script>

<style scoped>

  .p-gallery {
    padding: 150px 60px 200px 60px;
  }

  .g-switcher {
    display: flex;
    gap: 40px;
    margin-bottom: 50px;
  }
  .g-switcher a {
    color: #808080;
  }
  .g-switcher a.active {
    color: #000000;
  }

  @media (max-width: 1440px) {
    .p-gallery {
      padding: 150px 40px 150px 40px;
    }
  }

  @media (max-width: 1200px) {
    .p-gallery {
      padding: 120px 40px 120px 40px;
    }
  }


  @media (max-width: 450px) {
    .p-gallery {
      padding: 80px 20px 100px 20px;
    }
  }
</style>